import { MENU_ID } from '@/constants/my-info.const';

// TODO: recheck i18n
export const getMenuLabelById = (id: string): string => {
  switch (id) {
    case MENU_ID.GROUP_HOME:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_home';
    case MENU_ID.PROJECT_PRODUCT:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_prj_prod';
    case MENU_ID.COLLECTION:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_collection';
    case MENU_ID.STORE_COMMUNITY:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_store_comm';
    case MENU_ID.MEMBER_MANAGEMENT:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_mem_mngmt';
    case MENU_ID.BILL_SETTLEMENT:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_bill_settlement';
    case MENU_ID.STATISTICS:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_statistics';
    case MENU_ID.BIMS_BANK_SETTING:
      return 'studio.gnb.group_myinfo.my_group.detailed_group_per_biz_bank_info';
    default:
      return '-';
  }
};
